/*!
 * Festo UI - Web Essentials v3.1.0 (https://storybook.festo.design/)
 * Copyright 2022 Festo SE & Co. KG
 * Licensed under Apache-2.0
 */
/* a screen reader friendly approach */
footer.fwe-footer .fwe-social-media {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 24px 0px;
  border-bottom: 1px solid var(--fwe-control-border-active);
  margin-bottom: 24px;
}
footer.fwe-footer .fwe-social-media .fwe-social-media-link {
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  margin-right: 24px;
  opacity: 0.3;
  transition: opacity 0.3s;
}
footer.fwe-footer .fwe-social-media .fwe-social-media-link:last-child {
  margin-right: 0px;
}
footer.fwe-footer .fwe-social-media .fwe-social-media-link:hover {
  opacity: 1;
}
footer.fwe-footer .fwe-footer-details {
  padding-bottom: 24px;
  border-bottom: 1px solid var(--fwe-control-border-active);
}
footer.fwe-footer .fwe-footer-details h4 {
  font-size: 16px;
  line-height: 24px;
  font-weight: var(--fwe-font-weight-bold);
  list-style-type: none;
  position: relative;
  margin-top: 0px;
  margin-bottom: 12px;
}
footer.fwe-footer .fwe-footer-details ul.fwe-list-group .fwe-list-group-item {
  padding-left: 0px;
}
footer.fwe-footer .fwe-footer-details p {
  padding-top: 8px;
  max-width: 320px;
}
footer.fwe-footer .fwe-footer-details:not(:first-child) {
  margin-top: 24px;
}
footer.fwe-footer .fwe-bottomline {
  font-size: var(--fwe-font-size-small);
  padding-top: 24px;
  text-align: center;
}
footer.fwe-footer .fwe-bottomline .fwe-sitelinks {
  order: 2;
}
footer.fwe-footer .fwe-bottomline .fwe-sitelinks .fwe-sitelinks-groupa {
  padding-bottom: 8px;
}
footer.fwe-footer .fwe-bottomline .fwe-sitelinks .fwe-sitelinks-groupa a {
  color: var(--fwe-control-border-active);
  padding-right: 8px;
}
footer.fwe-footer .fwe-bottomline .fwe-sitelinks .fwe-sitelinks-groupa a:last-child {
  padding-right: 0px;
}
footer.fwe-footer .fwe-bottomline .fwe-sitelinks .fwe-sitelinks-groupb {
  padding-bottom: 24px;
}
footer.fwe-footer .fwe-bottomline .fwe-sitelinks .fwe-sitelinks-groupb a {
  color: var(--fwe-control-border-active);
  padding-right: 8px;
}
footer.fwe-footer .fwe-bottomline .fwe-sitelinks .fwe-sitelinks-groupb a:last-child {
  padding-right: 0px;
}
footer.fwe-footer .fwe-bottomline .fwe-copyright {
  display: flex;
  order: 1;
  justify-content: center;
  padding-bottom: 24px;
  color: var(--fwe-control-border-active);
}

@media only screen and (min-width: 1281px) {
  footer.fwe-footer .fwe-footer-details {
    border-bottom: none;
  }
  footer.fwe-footer .fwe-footer-details h4 {
    margin-top: 24px;
  }
  footer.fwe-footer .fwe-footer-details:not(:first-child) {
    margin-top: 0px;
  }
  footer.fwe-footer .fwe-bottomline {
    font-size: var(--fwe-font-size-small);
    padding-top: 24px;
    text-align: left;
    display: flex;
  }
  footer.fwe-footer .fwe-bottomline .fwe-sitelinks {
    text-align: right;
    margin-left: auto;
    display: flex;
  }
  footer.fwe-footer .fwe-social-media {
    margin-bottom: 0px;
  }
}
@media only screen and (min-width: 1441px) {
  footer.fwe-footer .fwe-social-media .fwe-social-media-link {
    width: 64px;
    height: 64px;
    margin-right: 42px;
  }
  footer.fwe-footer .fwe-social-media .fwe-social-media-link:last-child {
    margin-right: 0px;
  }
}
.fwe-teaser-horbox {
  display: flex;
  background-color: var(--fwe-sucanul);
}

.fwe-teaser-horbox .fwe-teaser-horbox-img {
  background-color: var(--fwe-hero);
  flex: 1;
}

.fwe-teaser-horbox .fwe-teaser-horbox-content {
  flex: 1;
  display: inline-flex;
  flex-direction: column;
  padding: 24px;
}

.fwe-teaser-horbox .fwe-teaser-horbox-content .fwe-teaser-more {
  align-self: flex-end;
}

.fwe-teaser-verbox {
  display: inline-flex;
  flex-direction: column;
  background-color: var(--fwe-sucanul);
  width: 100%;
  height: 100%;
}

.fwe-teaser-verbox .fwe-teaser-verbox-img {
  background-color: var(--fwe-hero);
  height: calc(50% - 12px);
  min-height: 186px;
  width: 100%;
}

.fwe-teaser-verbox h2 {
  padding-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
}

.fwe-teaser-verbox p {
  padding-left: 24px;
  padding-right: 24px;
}

.fwe-teaser-verbox .fwe-teaser-more {
  align-self: flex-end;
  margin-bottom: 24px;
  margin-right: 24px;
}

.fwe-teaser-c12-container {
  display: flex;
  flex-direction: column;
}
.fwe-teaser-c12-container .fwe-teaser-c12-content {
  order: 2;
  background-color: var(--fwe-sucanul);
  padding-top: 16px;
  padding-bottom: 13px;
  padding-left: 20px;
  padding-right: 20px;
}
.fwe-teaser-c12-container .fwe-teaser-c12-content h3,
.fwe-teaser-c12-container .fwe-teaser-c12-content .fwe-h3 {
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 4px;
  font-weight: var(--fwe-font-weight-normal);
}
.fwe-teaser-c12-container .fwe-teaser-c12-content h2,
.fwe-teaser-c12-container .fwe-teaser-c12-content .fwe-h2 {
  font-size: 16px;
  line-height: 24px;
  font-weight: var(--fwe-font-weight-bold);
  margin-top: 0;
}
.fwe-teaser-c12-container .fwe-teaser-c12-img {
  order: 1;
  background-color: var(--fwe-hero);
  min-height: 114px;
}

@media only screen and (min-width: 1025px) {
  .fwe-teaser-verbox h2 {
    padding-top: 48px;
  }
}
.fwe-header-slider-container .fwe-header-slider-box {
  height: 142px;
  background-color: var(--fwe-hero);
}
.fwe-header-slider-container .fwe-header-slider {
  margin-top: -28px;
  margin-left: 12px;
  margin-right: 12px;
}

.fwe-header-slider {
  display: flex;
  flex-direction: column;
  padding: 24px 28px;
  background-color: var(--fwe-white);
  border-radius: 4px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
}
.fwe-header-slider .fwe-header-slider-content {
  display: inline-flex;
  flex-direction: column;
}
.fwe-header-slider .fwe-header-slider-content p {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  margin: 0;
}
.fwe-header-slider .fwe-header-slider-content button.fwe-btn-hero {
  margin-top: 24px;
  justify-content: center;
  margin-left: 18px;
  margin-right: 18px;
}
.fwe-header-slider .fwe-pagination {
  align-self: center;
  margin-top: 24px;
}
.fwe-header-slider .fwe-header-slider-arrow-left,
.fwe-header-slider .fwe-header-slider-arrow-right {
  display: none;
}

@media only screen and (min-width: 600px) {
  .fwe-header-slider-container .fwe-header-slider-box {
    height: 166px;
  }
}
@media only screen and (min-width: 769px) {
  .fwe-header-slider-container .fwe-header-slider-box {
    height: 341px;
  }
  .fwe-header-slider-container .fwe-header-slider {
    margin-top: -104px;
  }
}
@media only screen and (min-width: 1025px) {
  .fwe-header-slider-container .fwe-header-slider-box {
    height: 455px;
  }
  .fwe-header-slider-container .fwe-header-slider {
    position: relative;
    margin-top: -104px;
  }
  .fwe-header-slider {
    padding: 24px 112px;
  }
  .fwe-header-slider .fwe-header-slider-content {
    flex-direction: row;
    align-items: center;
  }
  .fwe-header-slider .fwe-header-slider-content p {
    -webkit-line-clamp: 3;
  }
  .fwe-header-slider .fwe-header-slider-content button.fwe-btn-hero {
    justify-content: center;
    margin-top: 0;
    margin-left: 34px;
    margin-right: 0;
    min-width: 200px;
  }
  .fwe-header-slider .fwe-header-slider-arrow-left,
  .fwe-header-slider .fwe-header-slider-arrow-right {
    display: block;
    position: absolute;
    top: 50%;
    margin-top: 12px;
    transform: translateY(-50%);
    height: 48px;
    width: 48px;
    font-size: 48px;
  }
  .fwe-header-slider .fwe-header-slider-arrow-left {
    left: 24px;
  }
  .fwe-header-slider .fwe-header-slider-arrow-right {
    right: 24px;
  }
}
@media only screen and (min-width: 1441px) {
  .fwe-header-slider-container .fwe-header-slider-box {
    height: 640px;
  }
  .fwe-header-slider-container .fwe-header-slider {
    margin: auto;
    margin-top: -104px;
    width: 1096px;
  }
  .fwe-header-slider {
    padding: 24px 160px;
  }
}
@media only screen and (min-width: 1921px) {
  .fwe-header-slider-container .fwe-header-slider {
    width: 1192px;
  }
}
.fwe-header-modul-container {
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  padding-bottom: 32px;
}
.fwe-header-modul-container .fwe-header-modul-content {
  order: 2;
  position: relative;
}
.fwe-header-modul-container .fwe-header-modul-content h1,
.fwe-header-modul-container .fwe-header-modul-content .fwe-h1 {
  margin-bottom: 0;
}
.fwe-header-modul-container .fwe-header-modul-content h3,
.fwe-header-modul-container .fwe-header-modul-content .fwe-h3 {
  margin-top: 0;
  margin-bottom: 16px;
}
.fwe-header-modul-container .fwe-header-modul-content p {
  font-size: var(--fwe-font-size-xl);
  line-height: 32px;
  color: var(--fwe-text-light);
  margin: 0;
  padding-bottom: 34px;
}
.fwe-header-modul-container .fwe-header-modul-content::after {
  position: absolute;
  bottom: 0;
  right: 0;
  content: "";
  width: 28px;
  height: 10px;
  background-color: var(--fwe-gray-400);
}
.fwe-header-modul-container .fwe-header-modul-media {
  min-height: 111px;
  background-color: var(--fwe-hero);
  order: 1;
}

@media only screen and (min-width: 600px) {
  .fwe-header-modul-container .fwe-header-modul-media {
    min-height: 132px;
  }
}
@media only screen and (min-width: 769px) {
  .fwe-header-modul-container .fwe-header-modul-content h1,
  .fwe-header-modul-container .fwe-header-modul-content .fwe-h1 {
    margin-bottom: 6px;
  }
  .fwe-header-modul-container .fwe-header-modul-media {
    min-height: 268px;
  }
}
@media only screen and (min-width: 1025px) {
  .fwe-header-modul-container .fwe-header-modul-media {
    min-height: 352px;
  }
}
@media only screen and (min-width: 1281px) {
  .fwe-header-modul-container {
    flex-direction: row;
  }
  .fwe-header-modul-container .fwe-header-modul-content {
    order: 1;
    width: 41.6666666667%;
    margin-right: 24px;
  }
  .fwe-header-modul-container .fwe-header-modul-content h1,
  .fwe-header-modul-container .fwe-header-modul-content .fwe-h1 {
    margin-top: 0;
  }
  .fwe-header-modul-container .fwe-header-modul-media {
    order: 2;
    min-height: 293px;
    flex: 1;
  }
}
@media only screen and (min-width: 1441px) {
  .fwe-header-modul-container .fwe-header-modul-media {
    min-height: 400px;
  }
}
/* stylelint-disable selector-class-pattern -- Doing swiper overrides here */
.fwe-modal-image-gallery {
  background: transparent;
  box-shadow: none;
  padding: 0px;
  position: fixed;
  width: 288px;
  max-width: unset;
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
}
.fwe-modal-image-gallery .fwe-btn-link i.fwe-icon-arrows-scale-up.fwe-modal-image-gallery-scale,
.fwe-modal-image-gallery .fwe-btn-link i.fwe-icon-arrows-scale-down.fwe-modal-image-gallery-scale {
  font-size: 24px;
  margin-right: 0px;
  padding-right: 0px;
  padding-top: 3px;
}
.fwe-modal-image-gallery .fwe-image-gallery {
  max-height: calc(100vh - 73px);
}
.fwe-modal-image-gallery .fwe-image-gallery-close-btn {
  width: 32px;
  height: 32px;
  padding: 0;
  border: none;
  min-width: 32px;
  cursor: pointer;
  background: var(--fwe-white);
  -webkit-mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgzMnYzMkgweiIgZGF0YS1uYW1lPSJ3aGl0ZSBiYWNrZ3JvdW5kIi8+PHBhdGggZmlsbD0iIzMzMyIgZD0iTTIxLjQ4IDkuMSAxNiAxNC41OSAxMC41MSA5LjEgOS4xIDEwLjUyIDE0LjU4IDE2IDkuMSAyMS40OWwxLjQxIDEuNDFMMTYgMTcuNDJsNS40OCA1LjQ4IDEuNDItMS40MUwxNy40MSAxNmw1LjQ5LTUuNDgtMS40Mi0xLjQyeiIgZGF0YS1uYW1lPSJjb250ZW50Ii8+PC9zdmc+");
  mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgzMnYzMkgweiIgZGF0YS1uYW1lPSJ3aGl0ZSBiYWNrZ3JvdW5kIi8+PHBhdGggZmlsbD0iIzMzMyIgZD0iTTIxLjQ4IDkuMSAxNiAxNC41OSAxMC41MSA5LjEgOS4xIDEwLjUyIDE0LjU4IDE2IDkuMSAyMS40OWwxLjQxIDEuNDFMMTYgMTcuNDJsNS40OCA1LjQ4IDEuNDItMS40MUwxNy40MSAxNmw1LjQ5LTUuNDgtMS40Mi0xLjQyeiIgZGF0YS1uYW1lPSJjb250ZW50Ii8+PC9zdmc+");
}
.fwe-modal-image-gallery .fwe-image-gallery-close-btn:hover {
  background: var(--fwe-hero);
}
.fwe-modal-image-gallery .fwe-image-gallery-scale-btn {
  width: 24px;
  height: 24px;
  padding: 0;
  border: none;
  min-width: 24px;
  cursor: pointer;
  background: var(--fwe-white);
  -webkit-mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgzMnYzMkgweiIgZGF0YS1uYW1lPSJ3aGl0ZSBiYWNrZ3JvdW5kIi8+PGcgZGF0YS1uYW1lPSJjb250ZW50Ij48cGF0aCBkPSJtMTguMTAxIDQgMi44MjggMi44MjgtNC4yNDMgNC4yNDMgNC4yNDMgNC4yNDMgNC4yNDMtNC4yNDNMMjggMTMuODk5VjRoLTkuODk5ek0xMS4wNzEgMTYuNjg2bC00LjI0MyA0LjI0M0w0IDE4LjEwMVYyOGg5LjlsLTIuODI5LTIuODI4IDQuMjQzLTQuMjQzLTQuMjQzLTQuMjQzeiIvPjwvZz48L3N2Zz4=");
  mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgzMnYzMkgweiIgZGF0YS1uYW1lPSJ3aGl0ZSBiYWNrZ3JvdW5kIi8+PGcgZGF0YS1uYW1lPSJjb250ZW50Ij48cGF0aCBkPSJtMTguMTAxIDQgMi44MjggMi44MjgtNC4yNDMgNC4yNDMgNC4yNDMgNC4yNDMgNC4yNDMtNC4yNDNMMjggMTMuODk5VjRoLTkuODk5ek0xMS4wNzEgMTYuNjg2bC00LjI0MyA0LjI0M0w0IDE4LjEwMVYyOGg5LjlsLTIuODI5LTIuODI4IDQuMjQzLTQuMjQzLTQuMjQzLTQuMjQzeiIvPjwvZz48L3N2Zz4=");
}
.fwe-modal-image-gallery .fwe-image-gallery-scale-btn:hover {
  background: var(--fwe-hero);
}
.fwe-modal-image-gallery--with-thumbnails .fwe-image-gallery {
  max-height: calc(100vh - 161px);
}
.fwe-modal-image-gallery--with-container {
  border-top: none;
  border-bottom: none;
}
.fwe-modal-image-gallery--with-container .fwe-image-gallery-header {
  margin-top: 18px;
}

.fwe-image-gallery-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.fwe-image-gallery-content {
  color: var(--fwe-white);
  padding-top: 48px;
  padding-bottom: 24px;
}
.fwe-image-gallery-content h3 {
  font-weight: var(--fwe-font-weight-bold);
  margin-top: 0;
  margin-bottom: 16px;
}

.fwe-image-gallery-header {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  margin-top: 0px;
}

.fwe-image-gallery {
  width: 100%;
  height: 288px;
  background-color: white;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.fwe-image-gallery svg,
.fwe-image-gallery img,
.fwe-image-gallery .swiper-zoom-container img,
.fwe-image-gallery .swiper-zoom-container svg {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.fwe-image-gallery-thumbs {
  margin-top: 24px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.fwe-image-gallery-thumbs.swiper {
  width: 100%;
  height: 64px;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
}
.fwe-image-gallery-thumbs.swiper .swiper-slide {
  position: relative;
  background-size: cover;
  background-position: center;
  width: 88px;
  margin-right: 8px;
  height: 64px;
  opacity: 0.33;
  transition: opacity 0.3s;
  cursor: pointer;
}
.fwe-image-gallery-thumbs.swiper .swiper-slide:last-child {
  margin-right: 0px;
}
.fwe-image-gallery-thumbs.swiper .swiper-slide::after {
  position: absolute;
  content: "";
  height: 4px;
  width: 100%;
  background-color: var(--fwe-hero);
  bottom: 0px;
  opacity: 0;
  transition: opacity 0.3s;
}
.fwe-image-gallery-thumbs.swiper .swiper-slide img {
  display: block;
  -o-object-fit: cover;
  object-fit: cover;
  width: 88px;
  height: 56px;
  background-color: white;
}
.fwe-image-gallery-thumbs.swiper .swiper-slide.swiper-slide-thumb-active, .fwe-image-gallery-thumbs.swiper .swiper-slide:hover {
  opacity: 1;
}
.fwe-image-gallery-thumbs.swiper .swiper-slide.swiper-slide-thumb-active::after, .fwe-image-gallery-thumbs.swiper .swiper-slide:hover::after {
  opacity: 1;
}
.fwe-image-gallery-thumbs.swiper .swiper-slide.swiper-slide-thumb-active {
  cursor: default;
}
.fwe-image-gallery-thumbs:not(.swiper) {
  display: flex;
  flex-direction: row;
  overflow: hidden;
}
.fwe-image-gallery-thumbs-nail {
  position: relative;
  height: 64px;
  width: 88px;
  min-width: 88px;
  margin-right: 8px;
  opacity: 0.33;
  transition: opacity 0.3s;
  cursor: pointer;
}
.fwe-image-gallery-thumbs-nail img {
  position: absolute;
  height: 56px;
  width: 88px;
  min-width: 88px;
  background: white;
  -o-object-fit: cover;
  object-fit: cover;
}
.fwe-image-gallery-thumbs-nail:last-child {
  margin-right: 0px;
}
.fwe-image-gallery-thumbs-nail::after {
  position: absolute;
  content: "";
  height: 4px;
  width: 100%;
  background-color: var(--fwe-hero);
  bottom: 0px;
  opacity: 0;
  transition: opacity 0.3s;
}
.fwe-image-gallery-thumbs-nail.fwe-active, .fwe-image-gallery-thumbs-nail:hover {
  opacity: 1;
}
.fwe-image-gallery-thumbs-nail.fwe-active::after, .fwe-image-gallery-thumbs-nail:hover::after {
  opacity: 1;
}
.fwe-image-gallery-thumbs-nail.fwe-active {
  cursor: default;
}

@media only screen and (min-width: 375px) {
  .fwe-modal-image-gallery {
    width: 342px;
    max-height: 90vh;
  }
  .fwe-image-gallery,
  .fwe-image-gallery-content {
    height: 342px;
  }
}
@media only screen and (min-width: 769px) {
  .fwe-modal-image-gallery {
    width: 696px;
  }
  .fwe-image-gallery,
  .fwe-image-gallery-content {
    height: 465px;
  }
}
@media only screen and (min-width: 1025px) {
  .fwe-modal-image-gallery {
    width: 696px;
  }
  .fwe-image-gallery,
  .fwe-image-gallery-content {
    height: 465px;
  }
}
@media only screen and (min-width: 1441px) {
  .fwe-modal-image-gallery {
    width: 960px;
    min-height: 726px;
  }
  .fwe-modal-image-gallery--with-container {
    width: 1320px;
    border-top: none;
    border-bottom: none;
    position: relative;
  }
  .fwe-modal-image-gallery--with-container .fwe-image-gallery-header {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 312px;
    margin-top: 0px;
  }
  .fwe-image-gallery,
  .fwe-image-gallery-content {
    height: 640px;
  }
  .fwe-image-gallery-container {
    flex-direction: row;
  }
  .fwe-image-gallery-container .fwe-image-gallery {
    width: 984px;
  }
  .fwe-image-gallery-container .fwe-image-gallery-content {
    margin-top: 35px;
    max-width: 312px;
    width: 312px;
    min-width: 312px;
    margin-left: 24px;
    border-top: none;
    border-bottom: none;
  }
}
.fwe-login-layout {
  position: fixed;
  background: var(--fwe-background);
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.fwe-login-layout .fwe-login-background {
  top: 64px;
  position: absolute;
  height: calc(100vh - 64px);
  width: 100vw;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}
.fwe-login-layout .fwe-login-container {
  height: calc(100vh - 64px);
  overflow: auto;
  display: flex;
  align-items: center;
}
.fwe-login-layout .fwe-login-container .fwe-login-form {
  z-index: 1;
  margin-left: 20%;
  width: 432px;
  background: var(--fwe-white);
  padding: 48px;
}
.fwe-login-layout .fwe-login-container .fwe-login-form h1 {
  margin: 0px 0px 48px 0px;
}
.fwe-login-layout .fwe-login-container .fwe-login-form .fwe-login-link-container {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}
@media (max-width: 375px) {
  .fwe-login-layout .fwe-login-container .fwe-login-form {
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media (max-width: 600px), (max-height: 600px) {
  .fwe-login-layout .fwe-login-container {
    background-color: var(--fwe-white);
    position: unset;
    left: unset;
    top: unset;
    width: 100vw;
    align-items: stretch;
  }
  .fwe-login-layout .fwe-login-container .fwe-login-form {
    margin-left: 0px;
    width: 100%;
  }
  .fwe-login-layout .fwe-login-background {
    display: none;
  }
}

.fwe-side-menu {
  flex-shrink: 0;
  width: 240px;
  display: flex;
  flex-direction: column;
}
.fwe-side-menu .fwe-side-menu-item {
  border-left: 1px solid var(--fwe-border);
  line-height: 1.2;
  display: flex;
  align-items: center;
  height: 42px;
  cursor: pointer;
  margin-left: -1px;
  color: var(--fwe-text);
  padding: 0px 8px 0px 16px;
  position: relative;
}
.fwe-side-menu .fwe-side-menu-item::before {
  position: absolute;
  content: "";
  top: 0px;
  left: -1px;
  bottom: 0px;
  width: 0px;
  background: var(--fwe-hero);
  transition: width 0.2s, height 0.2s;
}
.fwe-side-menu .fwe-side-menu-item.fwe-active {
  color: var(--fwe-hero);
}
.fwe-side-menu .fwe-side-menu-item.fwe-active::before {
  width: 4px;
}
@media (max-width: 900px) {
  .fwe-side-menu {
    width: 190px;
  }
}
.fwe-side-menu--horizontal {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
}
@-moz-document url-prefix() {
  .fwe-side-menu--horizontal {
    scrollbar-width: thin;
    scrollbar-color: var(--fwe-control-scrollbar) transparent;
  }
}
@media (pointer: fine) {
  .fwe-side-menu--horizontal::-webkit-scrollbar {
    width: 14px;
  }
  .fwe-side-menu--horizontal::-webkit-scrollbar-track {
    background: transparent;
  }
  .fwe-side-menu--horizontal::-webkit-scrollbar-thumb {
    overflow: visible;
    background-color: var(--fwe-control-scrollbar);
    border: 3px solid rgba(242, 243, 245, 0);
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border-radius: 8px;
  }
}
.fwe-side-menu--horizontal .fwe-side-menu-item {
  border: unset;
  height: 48px;
  padding: 0px 16px;
  margin: 0px;
  white-space: nowrap;
}
.fwe-side-menu--horizontal .fwe-side-menu-item::before {
  top: unset;
  right: 16px;
  left: 16px;
  width: unset;
  height: 0px;
}
.fwe-side-menu--horizontal .fwe-side-menu-item.fwe-active::before {
  width: unset;
  height: 4px;
}

/*# sourceMappingURL=festo-web-essentials-organisms.css.map */